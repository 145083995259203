export const publications = [
  {
    title: 'Momentum Improves Optimization on Riemannian Manifolds',
    url: 'http://proceedings.mlr.press/v130/alimisis21a/alimisis21a.pdf',
    author: 'F Alimisis, A Orvieto, G Bécigneul, A Lucchi',
    place:
      'International Conference on Artificial Intelligence and Statistics, 1351-1359',
    year: 2021
  },
  {
    title:
      'Environment and Speaker Related Emotion Recognition in Conversations',
    url: 'https://dl.acm.org/doi/abs/10.1145/3448734.3450913',
    author: 'Z Zhong, S Yang, G Bécigneul',
    place:
      'The 2nd International Conference on Computing and Data Science, 1-6',
    year: 2021
  },
  {
    title: 'On the Geometry of Data Representations',
    url: 'https://www.research-collection.ethz.ch/bitstream/handle/20.500.11850/460877/1/thesis.pdf',
    author: 'G Bécigneul',
    place: 'ETH Zurich',
    year: 2021
  },
  {
    title: 'LassoNet: A Neural Network with Feature Sparsity',
    url: 'https://www.jmlr.org/papers/volume22/20-848/20-848.pdf',
    author: 'I Lemhadri, F Ruan, L Abraham, R Tibshirani',
    place: 'Journal of Machine Learning Research 22 (127), 1-29',
    year: 2021
  },
  {
    title: 'Bloom Origami Assays: Practical Group Testing',
    url: 'https://arxiv.org/pdf/2008.02641',
    author:
      'L Abraham, G Becigneul, B Coleman, B Scholkopf, A Shrivastava, A Smola',
    place: '',
    year: 2020
  },
  {
    title: 'Optimal transport graph neural networks',
    url: 'https://arxiv.org/pdf/2006.04804',
    author: 'G Bécigneul, OE Ganea, B Chen, R Barzilay, T Jaakkola',
    place: '',
    year: 2020
  },
  {
    title: 'Crackovid: Optimizing Group Testing',
    url: 'https://arxiv.org/pdf/2005.06413',
    author: 'L Abraham, G Bécigneul, B Schölkopf',
    place: '',
    year: 2020
  },
  {
    title:
      'Computationally Tractable Riemannian Manifolds for Graph Embeddings',
    url: 'https://arxiv.org/pdf/2002.08665',
    author: 'C Cruceru, G Bécigneul, OE Ganea',
    place:
      'AAAI 2021: Association for the Advancement of Artificial Intelligence',
    year: 2020
  },
  {
    title:
      'Competition analysis on the over-the-counter credit default swap market',
    url: 'https://arxiv.org/pdf/2012.01883',
    author: 'L Abraham',
    place: '',
    year: 2020
  },
  {
    title: 'Mixed-curvature Variational Autoencoders',
    url: 'https://onikle.com/articles/275419',
    author: 'O Skopek, OE Ganea, G Bécigneul',
    place: 'ICLR 2020: International Conference on Learning Representations',
    year: 2020
  },
  {
    title: 'Practical Accelerated Optimization on Riemannian Manifolds',
    url: 'https://ui.adsabs.harvard.edu/abs/2020arXiv200204144A/abstract',
    author: 'F Alimisis, A Orvieto, G Bécigneul, A Lucchi',
    place: 'AISTATS 2021: Artificial Intelligence and Statistics',
    year: 2020
  },
  {
    title: 'Adressing the global challenges of LIBRA',
    url: 'https://www.journaleska.com/index.php/bmi/article/view/4581',
    author: 'L Abraham, D Guegan',
    place: 'Bankers, Markets & Investors 161',
    year: 2020
  },
  {
    title: 'Constant Curvature Graph Convolutional Networks',
    url: 'http://proceedings.mlr.press/v119/bachmann20a/bachmann20a.pdf',
    author: 'G Bachmann, G Bécigneul, OE Ganea',
    place: 'ICML 2020: International Conference on Machine Learning',
    year: 2019
  },
  {
    title:
      'A Continuous-time Perspective for Modeling Acceleration in Riemannian Optimization',
    url: 'http://proceedings.mlr.press/v108/alimisis20a/alimisis20a.pdf',
    author: 'F Alimisis, A Orvieto, G Bécigneul, A Lucchi',
    place: 'AISTATS 2020: Artificial Intelligence and Statistics',
    year: 2019
  },
  {
    title: 'The other side of the Coin: Risks of the Libra Blockchain',
    url: 'https://arxiv.org/pdf/1910.07775',
    author: 'L Abraham, D Guegan',
    place: '',
    year: 2019
  },
  {
    title:
      'Breaking the Softmax Bottleneck via Learnable Monotonic Pointwise Non-linearities',
    url: 'http://proceedings.mlr.press/v97/ganea19a/ganea19a.pdf',
    author: 'OE Ganea, S Gelly, G Bécigneul, A Severyn',
    place: 'ICML 2019: International Conference on Machine Learning',
    year: 2019
  },
  {
    title: 'Poincaré GloVe: Hyperbolic Word Embeddings',
    url: 'https://arxiv.org/pdf/1810.06546',
    author: 'A Tifrea, G Bécigneul, OE Ganea',
    place: 'ICLR 2019: International Conference on Learning Representations',
    year: 2018
  },
  {
    title: 'Riemannian Adaptive Optimization Methods',
    url: 'https://arxiv.org/pdf/1810.00760',
    author: 'G Bécigneul, OE Ganea',
    place: 'ICLR 2019: International Conference on Learning Representations',
    year: 2018
  },
  {
    title:
      'Escaping Flat Areas via Function-Preserving Structural Network Modifications',
    url: 'https://openreview.net/pdf?id=H1eadi0cFQ',
    author: 'Y Kilcher, G Bécigneul, T Hofmann',
    place: '',
    year: 2018
  },
  {
    title: 'Hyperbolic Neural Networks',
    url: 'https://arxiv.org/pdf/1805.09112',
    author: 'OE Ganea, G Bécigneul, T Hofmann',
    place: 'NIPS 2018: Neural Information Processing Systems',
    year: 2018
  },
  {
    title: 'Hyperbolic Entailment Cones for Learning Hierarchical Embeddings',
    url: 'http://proceedings.mlr.press/v80/ganea18a/ganea18a.pdf',
    author: 'OE Ganea, G Bécigneul, T Hofmann',
    place: 'ICML 2018: International Conference on Machine Learning',
    year: 2018
  },
  {
    title: 'SAT solving techniques: a bibliography',
    url: 'https://arxiv.org/pdf/1802.05159',
    author: 'L Abraham',
    place: '',
    year: 2018
  },
  {
    title: 'Parametrizing filters of a CNN with a GAN',
    url: 'https://arxiv.org/pdf/1710.11386',
    author: 'Y Kilcher, G Bécigneul, T Hofmann',
    place: '',
    year: 2017
  },
  {
    title: 'On the effect of pooling on the geometry of representations',
    url: 'https://arxiv.org/pdf/1703.06726',
    author: 'G Bécigneul',
    place: '',
    year: 2017
  }
]
