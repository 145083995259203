import React from 'react'
import { publications } from '../assets/data/publications'
import PaddingPaper from '../components/styledComponents/PaddingPaper'
import TitleBox from '../components/styledComponents/TitleBox'
import TopContainer from '../components/styledComponents/TopContainer'
import StyledDivider from '../components/styledComponents/StyledDivider'
import MuiLink from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const Research = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <PaddingPaper>
          <TitleBox>
            <Typography variant='h3' component='h2' align='center'>
              Scientific Publications
            </Typography>
            <StyledDivider variant='middle' />
          </TitleBox>
          {publications.map((el, index) => (
            <Grid
              key={index}
              container
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <MuiLink href={el.url} alt={el.title}>
                          {el.title}
                        </MuiLink>
                      }
                      secondary={
                        <>
                          <Typography>{el.author}</Typography>
                          <Typography>{el.place}</Typography>
                          <Typography
                            sx={{ display: { xs: 'flex', md: 'none' } }}
                          >
                            {el.year}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Typography variant='body2'>{el.year}</Typography>
              </Grid>
            </Grid>
          ))}
        </PaddingPaper>
      </TopContainer>
    </Box>
  )
}

export default Research
